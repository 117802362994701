exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-ar-404-js": () => import("./../../../src/pages/ar/404.js" /* webpackChunkName: "component---src-pages-ar-404-js" */),
  "component---src-pages-ar-about-us-index-js": () => import("./../../../src/pages/ar/about us/index.js" /* webpackChunkName: "component---src-pages-ar-about-us-index-js" */),
  "component---src-pages-ar-contact-index-js": () => import("./../../../src/pages/ar/contact/index.js" /* webpackChunkName: "component---src-pages-ar-contact-index-js" */),
  "component---src-pages-ar-index-js": () => import("./../../../src/pages/ar/index.js" /* webpackChunkName: "component---src-pages-ar-index-js" */),
  "component---src-pages-ar-our-services-index-js": () => import("./../../../src/pages/ar/our services/index.js" /* webpackChunkName: "component---src-pages-ar-our-services-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-services-index-js": () => import("./../../../src/pages/our services/index.js" /* webpackChunkName: "component---src-pages-our-services-index-js" */)
}

